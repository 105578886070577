import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RequestManagementModel } from './request-management.model';
import { FilterService } from '../services/filter-service/filter.service';
import { Router } from '@angular/router';
import { RequestManagement } from './request-management.enum';
import { FavouriteService } from '../services/favourite-service/favourite.service';
import { RequestStatusType } from '../request-status-type/request-status-type.enum';
import { RequestService } from '../services/request-service/request.service';
import { RequestStatusTypeModel } from '../request-status-type/request-status-type.model';
import { CommonService } from '../services/common-service/common.service';



@Component({
    selector: 'requestmanagement',
    templateUrl: './request-management.component.html',
    styleUrls: ['./request-management.component.css'],
    providers: [FilterService, FavouriteService, MessageService, RequestService, ConfirmationService,CommonService]
})
export class RequestManagementComponent implements OnInit {

    requestManagementModelList: RequestManagementModel[] = [];
    growlMsgs: ToastModule[] = [];

    totalRecordCount: number;
    filterColumns: any[] = [];
    dataList: RequestManagementModel[] = [];
    @Input() requestOption: string = '';
    public copiedText: string = 'Copied';
    requestStatusTypeList: RequestStatusTypeModel[] = [];

    loggedInUserDisplayName = localStorage["msal.displayName"];

    constructor(private router: Router,
        private filterService: FilterService,
        protected requestService: RequestService,
        private confirmationService: ConfirmationService,       
        protected favouriteService: FavouriteService,
        protected messageService: MessageService,
        private commonService:CommonService) {

    }

    ngOnInit() {
        this.requestOption = RequestManagement.Mine;
    }

    protected showRequest(requestNumber:string,requestType:string) {
        this.router.navigateByUrl("request-types/"+ requestType + "/request/" + requestNumber);
    }

    protected clearFilter() {

        this.filterColumns.forEach(element => {
            var obj = document.getElementById('input' + element.Column + 'Filter');

            if (obj != null && element.Column != "IsCopy")
                (<HTMLInputElement>obj).value = '';
            else if (element.Column == "IsCopy")
                (<HTMLInputElement>obj).checked = false;
        });

        this.requestManagementModelList = this.filterService.filter('', '', this.filterColumns, this.dataList, true);
    }

    protected filter(column, value, filterColumns: any[], requestManagementModelList: RequestManagementModel[]) {
        if(column == 'RequestStatus') {
            value = value.map(function(s) {return s.Code;});
        }
        this.requestManagementModelList = this.filterService.filter(column, value, filterColumns, requestManagementModelList, false);
    }

    protected async deletefavourite(id: bigint): Promise<boolean> {
        return this.favouriteService.delete(id).then(res => {
            return true;
        })
            .catch(error => {
                this.messageService.add({ severity: 'error', summary: '', detail: "Error occured while deleting favorite. Contact Administrator" });
                return false;
            });
    }

    protected async createfavourite(requestNumber: string, favouriteModel: any): Promise<any> {
        return this.favouriteService.create(requestNumber, favouriteModel).then(res => {
            return true;
        })
            .catch(error => {
                this.messageService.add({ severity: 'error', summary: '', detail: "Error occured while creating favorite. Contact Administrator" });
                return false;
            })
    }

    public get requestStatusType(): typeof RequestStatusType {
        return RequestStatusType;
    }

    protected deleteRequest(requestNumber: string,requestType:string) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected request?',
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.requestService.deleteRequest(requestNumber,requestType).subscribe(response => {
                    this.requestManagementModelList = this.requestManagementModelList.filter(s => s.RequestNumber !== requestNumber);
                    this.totalRecordCount = this.requestManagementModelList.length;
                    this.messageService.add({ severity: 'success', summary: 'Request Deleted Successfully!', detail: '' });
                },
                    error => {
                        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
                    });
            },
            reject: () => {

            }
        });


    }

    private recallRequest(requestManagementModel: RequestManagementModel) {

        this.confirmationService.confirm({
            message: 'Are you sure you want to recall the request?',
            header: 'Recall Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.requestService.getRequestStatusTypes().subscribe(response => this.requestStatusTypeList = response,
                    error => {
                        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
                    },
                    () => {
                        var requestStatusTypeModel = this.requestStatusTypeList.find(s => s.Code == RequestStatusType.Draft);
                        this.changeRequestStatus(requestManagementModel, requestStatusTypeModel);
                    })
            },
            reject: () => {
            }
        });
    }

    public changeRequestStatus(requestManagementModel: RequestManagementModel, requestStatusTypeModel: RequestStatusTypeModel) {
        this.requestService.updateRequestStatus(requestManagementModel.RequestNumber, requestStatusTypeModel,requestManagementModel.RequestTypeName).subscribe(response => {
            requestManagementModel.RequestStatus = RequestStatusType.Draft;
            if (this.requestOption == RequestManagement.AdvancedSearch) {
                this.requestManagementModelList = this.requestManagementModelList.filter(s => s.RequestId != requestManagementModel.RequestId)
            }
            this.messageService.add({ severity: 'success', summary: 'Request recalled successfully', detail: '', life: 500 });
        },
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
            });
    }

    public checkRequester(requestType:string)
    {
        let isRequester = this.commonService.checkRequestForRequestType(requestType);
        return isRequester;
    }
}