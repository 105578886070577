import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { RequestManagementComponent } from '../request-management.component';
import { RequestManagementService } from 'src/app/services/request-management-service/request-management.service';
import { FilterService } from 'src/app/services/filter-service/filter.service';
import { RequestManagement } from '../request-management.enum';
import { RequestManagementModel } from '../request-management.model';
import { FavouriteService } from 'src/app/services/favourite-service/favourite.service';
import { FavouriteModel } from '../favourites/favourite.model';
import { RequestService } from 'src/app/services/request-service/request.service';
import { CommonService } from 'src/app/services/common-service/common.service';
import { MsalService } from "@azure/msal-angular";
import { RequestStatusTypeModel } from 'src/app/request-status-type/request-status-type.model';


@Component({
    selector: 'app-my-requests',
    templateUrl: './my-requests.component.html',
    styleUrls: ['../request-management.component.css'],
    providers: [MessageService, RequestManagementService, FilterService, FavouriteService, RequestService, ConfirmationService,CommonService]
})
export class MyRequestsComponent extends RequestManagementComponent implements OnInit {

    requestStatusTypeList: RequestStatusTypeModel[] = [];
    selectedRequestStatusTypeModel: RequestStatusTypeModel[] = [];

    constructor(router: Router,
        private requestManagementService: RequestManagementService,
        private authService: MsalService,
        messageService: MessageService,
        filterService: FilterService,
        favouriteService: FavouriteService,
        requestService: RequestService,
        confirmationService: ConfirmationService,commonService:CommonService) {
        super(router, filterService, requestService, confirmationService, favouriteService, messageService,commonService)
        this.requestOption = RequestManagement.Mine;
    }

    ngOnInit() {
        this.getMyRequests();
        this.filterColumns = [
            { Text: 'Recipient', Column: 'Recipient' },
            { Text: 'RequestStatus', Column: 'RequestStatus' },
            { Text: 'Year', Column: 'Year' },
            { Text: 'RequestTypeName', Column: 'RequestTypeName' },
            { Text: 'Copy', Column: 'IsCopy' }];
        
        this.getRequestStatusTypeList();
    }
    doNothing(e, index) {
        if (e) {
          e.stopPropagation();
        }
      }
      
    private getMyRequests() {
        this.requestManagementService.getMyRequests(localStorage["msal.userId"]).subscribe(response => {
            this.requestManagementModelList = response;
            this.requestManagementModelList = this.requestManagementModelList.sort((a, b) => b.RequestId - a.RequestId);

            this.dataList = this.requestManagementModelList;
            this.totalRecordCount = this.requestManagementModelList.length;
        },
            error => {
                this.authService.loginRedirect();
//      this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' });
            })
    }


    clearFilter(){
        this.selectedRequestStatusTypeModel = Array.from(this.requestStatusTypeList);
        super.clearFilter();
    }

    private getRequestStatusTypeList() {
        this.requestService.getRequestStatusTypes().subscribe(response => {
            this.requestStatusTypeList = response;
            this.selectedRequestStatusTypeModel = Array.from(this.requestStatusTypeList);
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        })
    }

    public favoriteClick(requestManagementModel: RequestManagementModel) {
        if (requestManagementModel.IsFavorite) {
            this.deletefavourite(requestManagementModel.FavoriteId).then(res => {
                if (res) {
                    this.messageService.add({ severity: 'success', summary: 'Request has been removed from your favorites list', detail: '' });
                    this.getMyRequests();
                }
            });
        }
        else {
            let favouriteModel = new FavouriteModel();
            favouriteModel.UserId = localStorage["msal.userId"];
            this.createfavourite(requestManagementModel.RequestNumber, favouriteModel).then(res => {
                if (res) {
                    this.messageService.add({ severity: 'success', summary: 'Request has been added to your favorites list', detail: '' });
                    this.getMyRequests();
                }

            });
        }
    }

    public getTitleStyle(requestManagementModel: RequestManagementModel) {
        switch (requestManagementModel.RequestStatus)
        {
          case  "Draft" :  return 'clsDraftTitleBar' ; break ;
          case  "Submitted" :  return 'clsSubmittedTitleBar' ; break ;
          case  "Ordered" :  return 'clsOrderedTitleBar' ; break ;
          case  "Cancelled" :  return 'clsCancelledTitleBar' ; break ;
          case "Material Approval": return 'clsMaterialApprovalTitleBar'; break;
          case  "Awaiting ITC" :  return 'clsAwaitingITCTitleBar' ; break ;
          case "ITC Rejected": return 'clsITCRejectedTitleBar'; break;
          case  "In Process" :  return 'clsInProcessTitleBar' ; break ;
          case "Shipped": return 'clsShippedTitleBar'; break;
        }
        return '';
      }
}
