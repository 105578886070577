import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { RequestModel, CommentModel, RequestCommentModel } from './request.model';
import { ConfirmationService } from 'primeng/api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RequesterInformationModel } from '../requester-information/requester-information.model';
import { StepperLabel } from './stepper-label.enum';
import { MessageService } from 'primeng/api';
import { RequestService } from 'src/app/services/request-service/request.service';
import { RequestStatusTypeModel } from 'src/app/request-status-type/request-status-type.model';
import { RequestStatusType } from 'src/app/request-status-type/request-status-type.enum';
import { MatStepper } from '@angular/material/stepper';
import { AppComponent } from 'src/app/app.component';
import { RecipientModel } from 'src/app/recipient-profile/recipient-profile/recipient.model';
import { CommonService } from 'src/app/services/common-service/common.service';
import { RegionModel } from 'src/app/region/region.model';
import { CountryService } from 'src/app/services/country-service/country.service';
import { Address } from 'cluster';
import { AddressModel } from 'src/app/address/address.model';
import { DatePipe } from '@angular/common';
import { RequestStatusClient } from "src/app/services/cpsampleshop-api-client.service"
import { FileAlreadyExistException } from '@angular-devkit/schematics';
import { request } from 'http';
import { catchError, tap } from 'rxjs/operators';
import { DelegateModel } from 'src/app/delegate/delegate.model';
import { DelegateService } from 'src/app/services/delegate-service/delegate.service';
import { UserService } from 'src/app/services/user-service/user.service';
import { UserModel } from 'src/app/user/user.model';
import { RequestItemService } from 'src/app/services/request-item-service/request-item.service';
import { RequesterInformationService } from 'src/app/services/requester-information-service/requester-information.service';
import { RequestItemModel } from '../add-chemical-details/request-item.model';
import { RequestItemAttachmentService } from 'src/app/services/request-item-attachment-service/request-item-attachment.service';
import { ConfigurationSettings } from 'src/app/configuration-settings';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css'],
  providers: [ConfirmationService,RequesterInformationService, MessageService, RequestService, RequestItemService, CountryService, CommonService, DatePipe, DelegateService, UserService, RequestItemAttachmentService]
})
export class RequestComponent implements OnInit {
  isLinear = false;
  requesterDetailsGroup: UntypedFormGroup;
  addChemicalDetailsGroup: UntypedFormGroup;
  addShippingAddressGroup: UntypedFormGroup;
  verifyAndSubmitGroup: UntypedFormGroup;
  requestNumber: string;
  requestType: string;
  serviceResponse: any;
  alchemistOrderResponse: any;
  copyRequester: boolean;
  delegates: DelegateModel[];
  isRequestCancelled: boolean = false;
  isRequestCancelAllowed: boolean = false;
  // To avoid the error on page load for variable RequestItemList assigning the variable with its variable.
  requestModel: RequestModel = { RequestId: 0, RequestCreatedOn: '', RequestNumber: '', RequestItemList: [], RequestStatus: '', RequestCreatedBy: '', RequesterInformationModel: {} };
  showAddShippingStep: boolean = false;
  requesterInformationModel: RequesterInformationModel;
  showPriorityError: boolean = false;
  showForecastError: boolean = false;
  verifyAndSubmitStep: boolean = false;
  requestCommentModel: RequestCommentModel;
  commentModel: CommentModel = { Id: 0, Comment1: '', EnteredByUserId: 0, EnteredAt: new Date() };

  requestStatusTypeList: RequestStatusTypeModel[] = [];
  showCopyRequest: boolean = false;
  activeStep: string;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  isStepsEditable: boolean = true;
  public requestTitle: string = "";
  loggedInUserEmail: string;
  showMissingSampleDetails: boolean = false;
  showMissingShippingDetails: boolean = false;
  disabledSubmit: boolean = false;
  disabledCancel: boolean = false;
  disabledComments: boolean = false;
  availableAmountChanged: boolean = false;
  isForecastRequest: boolean = false;
  isAmountExceeded:boolean = false;
  displayDialog: boolean = false;
  displayCountryChangedDailog: boolean = false;
  displayOverrideInsufficientQuantityDailog: boolean = false;
  shippingAddress: AddressModel;
  region: RegionModel;
  countries=[];
  users: UserModel[];
  shippingCountryChanged: boolean;
  shippingAddressPhoneMissing: boolean;
  requesterPhoneMissing: boolean;
  requesterAddressMissing: boolean;
  shippingAddressOrgName: boolean;
  userRequestTypeRoleNames: string[] = [];
  dataMissingMessageWidth: object = {
    'width': '100%'
  }
  isRequester: boolean = false;
  isAdministrator: boolean = false;
  showMissingQuestionnaireDetails: boolean = false;
  showPackedWithMissing:boolean = false;
  sampleCommentMissing:boolean = false;
  biologicalQuestionnaire: any;
  submitted:boolean= false;
  isPermitAttachmentNotFound = false;
  public euCenterFeature = ConfigurationSettings.CurrentEnvironment.featureFlags.euCenter;

  constructor(private router: Router, private requestItemService: RequestItemService, private activatedroute: ActivatedRoute, private _formBuilder: UntypedFormBuilder,
    private messageService: MessageService, private requestService: RequestService,
    private appComponent: AppComponent,
    private countryService: CountryService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private requestStatusClient: RequestStatusClient,
    private datepipe: DatePipe,
    private delegateService: DelegateService,
    private requesterInformationService:RequesterInformationService,
    private userService: UserService,
    private requestItemAttachmentService: RequestItemAttachmentService) { }

  ngOnInit() {
    //To get request number passed from card detail page.
    //debugger;
    this.showMissingQuestionnaireDetails = false;
    this.showPackedWithMissing = false;
    this.requestNumber = this.activatedroute.snapshot.paramMap.get("requestnumber");
    this.requestType = this.activatedroute.snapshot.paramMap.get("requesttype");
    this.activeStep = this.activatedroute.snapshot.paramMap.get("activestep");
    this.isRequester = this.commonService.checkRequester();
    this.isAdministrator = this.commonService.checkAdministrator();
    this.loggedInUserEmail = localStorage["loggedInUsername"];
    if (this.activeStep == 'verifyandsubmit') {
      this.makeRequestNotEditable();

    }
    if (this.requestNumber) {
      this.getRequestComment();
    }

    if (this.requestNumber !== undefined && this.requestType !== undefined) {
      this.getRequestDetails();
      console.log('1', this.requestModel);
    }

    this.requesterDetailsGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.addChemicalDetailsGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.addShippingAddressGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.verifyAndSubmitGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    console.log('status', this.requestModel.RequestStatus)
    this.getRequestDelegators();
    this.getCurrentUserRole();
  }

  public getRequestModel(requestModel: RequestModel) {

    this.requestModel = requestModel;
    // To break the references converting to JSON.
    this.requesterInformationModel = JSON.parse(JSON.stringify(requestModel.RequesterInformationModel));
    // If  request information is null for the request.
    this.requesterInformationModel.RequestId = this.requestModel.RequestId;

    this.getRequestTitle();

    if (this.requestModel.RequestStatus != RequestStatusType.Draft) {
      this.makeRequestNotEditable();
    }
    else {
      this.stepper.selectedIndex = StepperLabel.RequesterDetails;
      this.disabledSubmit = false;
    }

    var delegate = this.delegates.find(x => x.RequesterEmail == this.requesterInformationModel.Email && x.RequestTypeName == this.requestType);

    if ((this.requesterInformationModel.Email != this.loggedInUserEmail
      && delegate == null && localStorage["msal.displayName"] != this.requestModel.RequestCreatedBy)) {
      this.copyRequester = true;
      this.stepper.selectedIndex = StepperLabel.VerifyAndSubmit;
      this.verifyAndSubmitStep = true;
      this.isStepsEditable = false;
      this.disabledSubmit = true;
    }
  }

  private getRequestDelegators() {
    this.delegateService.getDelegators().subscribe(response => this.delegates = response,
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
      },
      () => {
        this.delegates = this.delegates.filter(x => x.DelegatorName == localStorage["msal.displayName"]);
      })
  }

  private getRequestTitle() {
    var requestTypeName = this.requestModel.RequestTypeName == "P2P" ? "Peer to Peer" : this.requestModel.RequestTypeName
    if (this.appComponent.previousRouteURL == '/cart') {
      this.requestTitle = "New " + requestTypeName + " Request";
    }
    else if (this.requestModel.RequestStatus == RequestStatusType.Draft) {
      this.requestTitle = this.requestModel.RequestStatus + " " + requestTypeName + " Request";
    }
    else if (this.requestModel.RequestStatus == RequestStatusType.Submitted) {
      this.requestTitle = this.requestModel.RequestStatus + " " + requestTypeName + " Request";
    }
  }

  public getUpdatedRequestInformationModel(requesterInformationModel: RequesterInformationModel) {
    this.requestModel.RequesterInformationModel = requesterInformationModel;
    this.requesterPhoneMissing = this.requestModel.RequesterInformationModel.ContactNumber === undefined || this.requestModel.RequesterInformationModel.ContactNumber === null || this.requestModel.RequesterInformationModel.ContactNumber === '';
    this.requesterAddressMissing = this.requestModel.RequestTypeName == 'P2P' && (this.requestModel.RequesterInformationModel.RequesterAddress?.AddressId == null || this.requestModel.RequesterInformationModel.RequesterAddress?.AddressId == undefined);
    this.UpdateSubmitButtonStatus();
  }

  public UpdateSubmitButtonStatus() {
    this.disabledSubmit = (this.requesterAddressMissing || this.shippingAddressOrgName || this.requesterPhoneMissing || this.showMissingSampleDetails || this.shippingAddressPhoneMissing || this.showMissingShippingDetails || this.showPriorityError || this.showForecastError) ? true : false;
  }

  public confirmCancelOrder() {
    console.log("cancelOrder")
    this.CancelRequestConfirm();
    // if(this.serviceResponse.AlchemistOrderId != null)
    // {
    //   this.requestService.ValidateCancelRequest(this.serviceResponse.AlchemistOrderId).subscribe
    //   (response =>this.alchemistOrderResponse = response,
    //     error => {
    //       this.messageService.add({ severity: 'error', summary: '', detail: error.error });
    //     },
    //     () => {

    //       if(this.alchemistOrderResponse == null)
    //       {
    //         this.messageService.add({ severity: 'error', summary: '', detail: "Getting Alchemist Order status details failed. please check" });
    //       }
    //       else if(this.alchemistOrderResponse.OrderStatusID == 8 || this.alchemistOrderResponse.OrderStatusID == 10)
    //       {
    //         this.displayDialog = true;
    //       }
    //       else{
    //         this.CancelRequestConfirm();
    //       }
    //     });
    // }
    // else{
    //   this.CancelRequestConfirm()
    // }
  }

  private CancelRequestConfirm() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this order?',
      header: 'Cancel Order Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelRequest();
      },
      reject: () => {

      }
    });
  }

  public cancelRequest() {
    this.requestService.cancelRequest(this.requestNumber).subscribe(response => this.isRequestCancelled = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        if (this.isRequestCancelled) {
          this.messageService.add({ severity: 'success', summary: 'Order has been cancelled', detail: '', life: 500 });
          setTimeout(() =>
            this.router.navigateByUrl("/requestmanagement")
            , 500);

        }
        else {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Order could not be cancelled in Alchemist. Cancellation could not be done.' });
        }
      })
  }

  public Cancel() {
    this.displayDialog = false;
    this.displayCountryChangedDailog = false;
  }

  public CancelOverrideInsufficientQuantityDailog() {
    this.displayOverrideInsufficientQuantityDailog = false;
  }

  private getRequestComment() {
    this.requestService.getRequestComment(this.requestNumber).pipe(
      catchError(err => {
        this.commentModel = {
          Id: null,
          Comment1: null,
          EnteredByUserId: null,
          EnteredAt: null,
        };
        throw err;
      }),
      tap((res: any) => {
        if (res !== null && res !== undefined && res !== '') {
          this.commentModel = res;
        } else {
          this.commentModel = {
            Id: null,
            Comment1: null,
            EnteredByUserId: null,
            EnteredAt: null,
          };
        }
      })
    ).subscribe()
  }


  private getCurrentUserRole() {
    this.userService.getUsers().subscribe(response => this.users = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
      },
      () => {
        this.users.forEach(user => {
          if (user.Email === this.loggedInUserEmail) {
            user.UserRequestTypeRoleList?.forEach(role => {
              this.userRequestTypeRoleNames.push(role?.RoleName);
            });
          }
        });
      })
  }

  //---------------------------------------------
  // Fires when on focus lose of the Comment
  //---------------------------------------------
  public saveRequestComment(commentModel) {
    if (commentModel === null || commentModel === undefined) {

    }
    if (commentModel.Comment1 !== null && commentModel.Comment1 !== undefined &&
        commentModel.Comment1.length > 0) {
      commentModel.Id = 0;
      commentModel.EnteredByUserId = 0;
      commentModel.EnteredAt = new Date();
      this.requestService.saveRequestComment(commentModel, this.requestNumber).subscribe();
    }
  }

  //---------------------------------------------
  // Fire when user change the stepper step
  //---------------------------------------------
  public async onStepChange(event) {
   this.showAddShippingStep = this.verifyAndSubmitStep = false;
    if (event.selectedIndex == StepperLabel.AddShippingAddress) {
      this.showAddShippingStep = true;
    }
    else if (event.selectedIndex == StepperLabel.VerifyAndSubmit) {
      this.verifyAndSubmitStep = true;

      if (this.requestModel.RequestStatus == RequestStatusType.Draft) {
        await this.getRequestShipmentAddress();
        await this.getRequestItems();

        if(this.requestModel.RequestTypeName == "P2P")
        {
          await this.getRequesterInfo();
        }
        console.log("reqq", this.requestModel.RequestItemList);
        //let flagSample = (this.requestModel.RequestItemList.findIndex(s => (s.ContainerLabel == undefined || s.NeedByDate == undefined || s.RequestItemContainerList.length == 0))); // Commented as per KAT-404 Need by date is future implementation
        let flagSample = (this.requestModel.RequestItemList.findIndex(s => (s.ContainerLabel === undefined || s.ContainerLabel === null || s.ContainerLabel.trim() === '' || s.RequestItemContainerList.length == 0)));

         let  commentsMissing  = (this.requestModel.RequestItemList.filter(s => ((s.MaterialSearchType == 'P2PBiological' || s.MaterialSearchType == 'P2POther') &&
          (s.RequestItemComment == null ||
        s.RequestItemComment.length == 0 ||
        s.RequestItemComment[0].Comment == null ||
        s.RequestItemComment[0].Comment.Comment1 == "")  ) )).length;
        this.sampleCommentMissing = commentsMissing > 0;

        this.showPriorityError = !(this.requestModel.RequestItemList.filter(x => x.Priority == "Standard").length == this.requestModel.RequestItemList.length || this.requestModel.RequestItemList.filter(x => x.Priority == "Urgent").length == this.requestModel.RequestItemList.length || this.requestModel.RequestItemList.filter(x => x.Priority == undefined).length == this.requestModel.RequestItemList.length)
        this.showForecastError = !(this.requestModel.RequestItemList.filter(x => x.FarmLocationId != null).length == this.requestModel.RequestItemList.length || this.requestModel.RequestItemList.filter(x => x.FarmLocationId == null).length == this.requestModel.RequestItemList.length)
        this.showMissingSampleDetails = (flagSample >= 0 || this.requestModel.RequestItemList.length == 0) ? true : false;
        if (!this.showMissingSampleDetails) {
          this.requestModel.RequestItemList.filter(s => s.NeedByDate != undefined)
            .map(s => this.validateNeedByDate(s.NeedByDate));
        }
        // if(this.requestModel.RequestItemList.filter(x => (x.MaterialSearchType == "P2PBiological")).length > 0)
        // {
        // this.validateAndGetBiologicalQuestionnaire(this.requestModel.RequestNumber);
        // }
        if(this.requestModel.RequestItemList.filter(x => (x.MaterialSearchType == "P2PBiological" || x.MaterialSearchType == "P2POther")).length > 0)
        {
          this.showPackedWithMissing = ((this.requestModel.RequestItemList.filter(x => (x.MaterialSearchType == "P2PBiological" || x.MaterialSearchType == "P2POther") && x.PackedWithChoiceName == null).length > 0 ) ||
                                      (this.requestModel.RequestItemList.filter(x => (x.MaterialSearchType == "P2PBiological" || x.MaterialSearchType == "P2POther") && x.PackedWithChoiceName == "").length > 0) ||
                                      (this.requestModel.RequestItemList.filter(x => (x.MaterialSearchType == "P2PBiological" || x.MaterialSearchType == "P2POther") && x.PackedWithChoiceName == undefined).length >0))
        }
        // this.showMissingQuestionnaireDetails = this.countryService.isQuestionnaireSaved;
        if(this.requestModel.RequestItemList.filter(c => c.RequestLocationId > 0).length > 0)
        {
          this.availableAmountChanged =  (this.requestModel.RequestItemList.filter(c => c.RequestLocationId > 0 &&   c.AmountRemaining < 0)).length > 0 ? true : false;
          this.isForecastRequest = true;
          this.isAmountExceeded = false;
        }
        else
        {
          this.isAmountExceeded =(this.requestModel.RequestTypeName != "P2P" 
                          && (this.requestModel.IsIndyAligned || this.euCenterFeature)
                          && (this.requestModel.RequestItemList.filter(c => c.MaterialSearchType != 'P2POther' && c.MaterialSearchType != 'P2PBiological' &&  c.AmountRemaining < c.TotalRequestedQuantity)).length > 0) ? true : false;
          this.isForecastRequest = false;
        }

        this.showMissingShippingDetails = this.requestModel.ShippingOption == '' ? true : false;
        this.shippingCountryChanged = await this.hasShippingCountryChanged();
        this.shippingAddressPhoneMissing = this.shippingAddress?.Mobile === undefined || this.shippingAddress?.Mobile === null || this.shippingAddress?.Mobile === '';
        this.requesterPhoneMissing = this.requestModel.RequesterInformationModel.ContactNumber === undefined || this.requestModel.RequesterInformationModel.ContactNumber === null || this.requestModel.RequesterInformationModel.ContactNumber === '';
        this.requesterAddressMissing = this.requestModel.RequestTypeName == 'P2P' && (this.requestModel.RequesterInformationModel.RequesterAddress?.AddressId == null || this.requestModel.RequesterInformationModel.RequesterAddress?.AddressId == undefined);
        this.shippingAddressOrgName = this.requestModel.RequestTypeName == 'P2P' && (this.shippingAddress?.OrgName === undefined || this.shippingAddress?.OrgName === null);
        this.UpdateSubmitButtonStatus();
      }
      else {
        this.showMissingSampleDetails = this.showMissingShippingDetails = false;
        this.availableAmountChanged = this.availableAmountChanged = false;
        this.disabledSubmit = true;
      }

      // validate permit doc uploaded or not
      this.isPermitAttachmentNotFound = false;
      let docTypePermitObj;
      this.requestItemAttachmentService.GetDocumentTypeList().subscribe((response) => {
        docTypePermitObj = response.filter(x => x.Name === 'Permit')[0];

        const uploadedState = [];
        this.requestModel.RequestItemList.map(item => {
          if (item.IsPermitRequired === true) {
            const isPermitFound = item.RequestItemAttachmentList.filter(x => x.DocumentTypeId === docTypePermitObj.Id);
            if (isPermitFound.length === 0 || !item.IsPermitAvailable) {
              uploadedState.push(true);
            } else {
              uploadedState.push(false);
            }
          }
        });
        uploadedState.forEach(state => {
          if (state === true) {
            this.isPermitAttachmentNotFound = true;
          }
        })
      });
      
      // permit doc validation ends here
    }

    var delegate = this.delegates.find(x => x.RequesterEmail == this.requesterInformationModel.Email && x.RequestTypeName == this.requestType);

    if (this.requesterInformationModel.Email != this.loggedInUserEmail
      && delegate == null && localStorage["msal.displayName"] != this.requestModel.RequestCreatedBy
      && this.userRequestTypeRoleNames.indexOf('Administrator') == -1
      && this.userRequestTypeRoleNames.indexOf('Support') == -1) {
      this.disabledComments = true;
      this.disabledSubmit = true;
      this.disabledCancel = true;
    }
  }

  private validateAndGetBiologicalQuestionnaire(requestId) {
    this.showMissingQuestionnaireDetails = false;
    let showChemicalQuestionNoDataError = false;
    this.requestItemService.getBiologicalQuestionnaire(requestId).pipe(
      tap(res => {
        this.biologicalQuestionnaire = res;
        // console.log("Quesnaire: ", this.biologicalQuestionnaire);
        if (this.biologicalQuestionnaire != undefined && this.biologicalQuestionnaire != null && this.biologicalQuestionnaire.length > 0) {


          this.biologicalQuestionnaire.forEach(question => {
            const value = question?.RequestItemQuestionResponses[0]?.Response;
            if (value == null || value == undefined || value == "") {
              this.showMissingQuestionnaireDetails = true;
            }
          });
        }
        else {
          this.showMissingQuestionnaireDetails = true;
        }

      })
    ).subscribe();
  }

  public async hasShippingCountryChanged() {
    
    if (this.requestModel.IsForecastRequest && this.shippingAddress != undefined && !this.requestModel.IsRegion && this.requestModel.RequestItemList.filter(x => x.Country != this.shippingAddress.Country).length > 0) {
      return true;
    }
    else
     if (this.requestModel.IsForecastRequest && this.shippingAddress != undefined && this.requestModel.IsRegion && this.requestModel.CountryName !== 'Default Global Area') {
      var countriesList = await this.getRegionForCountry();
      var shippingRegion =  this.countries.filter(c => c.CountryId == this.shippingAddress.CountryId)[0];
      var requestCountries = this.requestModel.RequestItemList.map(a => a.Country);
      if (this.countries.filter(x => requestCountries.indexOf(x.Name) >= 0).filter(y => y.RegionId != shippingRegion.RegionId).length > 0) {
        return true;
      }
    }
    return false;
  }

  public getRegionForCountry(): Promise<any> {
    return this.countryService.getCountryListAsync().then(res => {
      this.countries = res;
    })
      .catch(error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
      });

  }

  getRecipientModel(recipientModel: RecipientModel) {
    if (!this.showMissingShippingDetails && this.requestModel.RequestStatus == RequestStatusType.Draft) {

      this.showMissingShippingDetails = recipientModel.ContactNumber == '' ? true : false;
    }
  }

  public getRequestShipmentAddress(): Promise<any> {
    if (this.requestModel.ShippingOption != "") {
      return this.requestService.getRequestShippingDetailAsync(this.requestModel.ShippingOption, this.requestModel.RequestNumber).then(response => {
        if (response.Recipient.RecipientId > 0) {
          this.shippingAddress = response.ShipmentAddressModel;
          this.requestModel.IsIndyAligned = response.ShipmentAddressModel.IsIndyAligned;
        }
      })
        .catch(error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        });
    }
  }
  public getRequestItems(): Promise<any> {
      return this.requestItemService.getRequestItemsAsync(this.requestModel.RequestTypeName, this.requestModel.RequestNumber).then(response => {
        if (response) {
          this.requestModel.RequestItemList = response;
        }
      })
        .catch(error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        });
    }

  public getRequesterInfo(): Promise<any> {
    if (this.requestModel.ShippingOption != "") {
      return this.requesterInformationService.getRequesterAsync(this.requestModel.RequestId).then(response => {
        console.log(response);
        if (response != null) {
          this.requestModel.RequesterInformationModel = response
        }
      })
        .catch(error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        });
    }
  }

  public async submitRequest($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    var isCountryChanged =  await this.hasShippingCountryChanged();
    if(isCountryChanged && !this.displayCountryChangedDailog && !this.displayOverrideInsufficientQuantityDailog)
    {
      this.displayCountryChangedDailog = true;
      return false;
    }
    else
    {
      this.displayCountryChangedDailog = false;
    }

    if ((this.isAmountExceeded || this.availableAmountChanged) && this.isAdministrator) {
      if (this.displayOverrideInsufficientQuantityDailog) {
        this.displayOverrideInsufficientQuantityDailog = false;
      }
      else {
        this.displayOverrideInsufficientQuantityDailog = true;
        return false;
      }
    }

    if(this.submitted)
    {
      return false;
    }

    this.messageService.clear('submitValidationNotification');
    var emptyFieldsElements = document.getElementsByClassName('mandatory-field');
    if (emptyFieldsElements.length > 0) {
      this.messageService.add({ key: 'submitValidationNotification', severity: 'error', summary: 'Data for a few fields needs to be validated. Please verify', detail: '' });
    }
    else {
      this.submitted= true;
      this.requestService.getRequestStatusTypes().subscribe(response => this.requestStatusTypeList = response,
        error => {
          this.submitted= false;
          this.messageService.add({ severity: 'error', summary: '', detail: error.error });
        },
        () => {
          var requestStatusTypeModel = this.requestStatusTypeList.find(s => s.Code == RequestStatusType.Submitted);
         if (this.requestModel.RequestItemList.find(a => (a.IsApproved === null || a.IsApproved === undefined) 
              && a.ApproverEmail !== undefined && a.ApproverEmail !== null 
              && a.ApproverEmail !== this.requestModel.RequesterInformationModel.Email
              && (a.FarmLocationId === null || a.FarmLocationId === undefined) ) !== undefined) {
           requestStatusTypeModel = this.requestStatusTypeList.find(s => s.Code == RequestStatusType.MaterialApproval);
         }
      
          this.changeRequestStatus(this.requestModel.RequestNumber, requestStatusTypeModel);
        })
    }
  }

  public changeRequestStatus(requestNumber: string, requestStatusTypeModel: RequestStatusTypeModel) {
    var requestStatusChangeResponse = '';
    this.requestService.updateRequestStatus(requestNumber, requestStatusTypeModel, this.requestType).subscribe(
      response => requestStatusChangeResponse = response,
      error => {
        this.submitted= false;
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.submitted= false;
        this.requestModel.RequestStatus = RequestStatusType.Submitted;
    this.requestModel.RequestStatus = this.requestModel.RequestItemList.find(a => a.ApproverEmail !== undefined && a.ApproverEmail !== null && (a.FarmLocationId === null || a.FarmLocationId === undefined) ) !== undefined ? RequestStatusType.MaterialApproval : RequestStatusType.Submitted;
        this.messageService.add({ severity: 'success', summary: 'Request submitted successfully', detail: '', life: 500 });
        this.router.navigateByUrl("/requestmanagement");
      })
  }

  public showCopyRequestPopUp() {
    this.showCopyRequest = true;
  }

  public closeCopyRequestPopUp() {
    this.showCopyRequest = false;
  }

  public makeRequestNotEditable() {
    this.stepper.selectedIndex = StepperLabel.VerifyAndSubmit;
    this.verifyAndSubmitStep = true;
    this.isStepsEditable = false;
    this.disabledSubmit = true;
  }

  private getRequestDetails() {
    this.requestService.getRequestDetail(this.requestNumber, this.requestType).subscribe(response => this.serviceResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.requestModel.RequestStatus = this.serviceResponse?.RequestStatus;

      })
  }

  //---------------------------------------------
  // NeedByDate should be greater than today
  //---------------------------------------------
  public validateNeedByDate(needBydate: Date) {
    let format = 'yyyy-MM-dd';
    let today = this.datepipe.transform(new Date().getTime(), format);
    if (this.datepipe.transform(needBydate, format) <= today) {
      return false;
    }
    return true;
  }
  public disableButton()
  {
    return ((this.isAmountExceeded || this.availableAmountChanged) && !this.isAdministrator) || 
    this.showMissingQuestionnaireDetails ||
    this.sampleCommentMissing || this.shippingAddressOrgName || this.requesterAddressMissing || 
    this.showMissingShippingDetails
                           ||  this.showPackedWithMissing || this.showPriorityError || 
                           this.disabledSubmit || this.showForecastError || this.requestModel.RequestStatus == 'Ordered' || 
                           this.requestModel.RequestStatus == 'Material Approval' || this.requestModel.RequestStatus ==  'Submitted' || 
                           this.requestModel.RequestStatus ==  'Cancelled' || this.requestModel.RequestStatus ==  'Awaiting ITC' || 
                           this.requestModel.RequestStatus ==  'In Process' || this.requestModel.RequestStatus ==  'Shipped' || 
                           this.requestModel.RequestStatus ==  'ITC Rejected'
                           || this.isPermitAttachmentNotFound 
  }

}

